import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import AccessConfig from '../AccessConfig/accessConfig';

const Routes = ({ isLoggedInUser }) => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={'/accessconfig'}
          render={() => isLoggedInUser && <AccessConfig />}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
