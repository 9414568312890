export const RBAC_CONSTANTS = {
  TEMPORARY_EMAIL_ID: 'harshraj@deloitte.com',
  LOGOUT: 'Logout',
  LANDING_PAGES: {
    GLOBE: 'Globe',
    PERFORMANCE_MANAGEMENT: 'Performance Management',
    ASSET_MANAGEMENT: 'Asset Management',
    ORDER_MANAGEMENT: 'Order Management',
  },

  LEVELS: {
    PLANT: 'Plant',
    BUSINESS_UNIT: 'Business Unit',
    GLOBAL: 'Global',
  },

  PERMISSIONS: {
    // Arrange in heirachy
    VIEW_ONLY: 'View Only',
    EDIT_ONLY: 'Edit Only',
    EDIT_AND_CONFIGURE: 'Edit and Configure',
    SYSTEM_ADMIN: 'System Admin',
  },

  ADMIN_ROLES: { 
    PLANT: 'PlantSystemAdmin',
    BUSINESS_UNIT: 'BusinessUnitSystemAdmin',
    GLOBAL: 'GlobalSystemAdmin'
  }
};

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

export const APP_CONTEXT = {
  COMPANY_DATA: 'COMPANY_DATA',
  UPDATE_COMPANY_NAME: 'UPDATE_COMPANY_NAME',
};

export const LOGO_TEXT = 'Smart Factory Fabric';

export const ACCESS_CONFIGURATION_CONSTANTS = {
  MANDATORY_FIELDS: 'Mandatory Fields',
  ASTERIX: '*',
  BUTTON_NAME: ['Save', 'Reset', 'Update', 'Cancel', 'Delete', 'Apply', 'No', 'Yes'],
  MAIN_HEADING: 'User Access Configuration',
  DELETE_BUTTON_LABLES: {
    YES: 'Yes',
    NO: 'No',
  },
  TAB_ITEMS: [
    {
      label: 'Role Configuration',
      value: 0,
      disabled: false,
    },
    {
      label: 'User Configuration',
      value: 1,
      disabled: false,
    },
  ],
}

export const USER_CONFIGURATION_CONSTANTS = {
  ADD_ROLE: 'Add Role',
  ROW_ID: 'rowID',
  HEADER_TITLES: ['Edit User', 'Add User'],
  TABLE_HEADER_CONSTANTS: [
    'Business Unit/Plant',
    'Role',
    'Role Assignment(Start Date)',
    'Role Assignment(End Date)',
    'Business Function',
    'Permission',
    'Landing Page',
    'Default Landing Page',
  ],
  TABLE_HEADER_KEYS: [
    'plant',
    'role',
    'startDate',
    'endDate',
    'function',
    'permission',
    'landingPage',
    'default',
  ],
  TABLE_HEADER_PLACEHOLDERS: ['Select Business Unit/Plant', 'Select Role'],
  ROLE_LIST: 'roleList',
  ROLE_ID: 'roleID',
  TEXT_FIELD_CONSTANTS: [
    'First Name',
    'Last Name',
    'User Access Start Date',
    'User Access End Date',
    'Email ID',
  ],
  TEXT_BOX_CONSTANTS: [
    {
      GRID_SIZE: 4,
      LABEL: 'First Name',
      TYPE: 'string',
      ID: 'firstName',
      REQUIRED: true,
      PATTERN: /^([a-z]){0,255}$/i,
      MESSAGE_EMPTY: 'Please enter the first name',
      MESSAGE_INVALID: 'Invalid first name !',
      PLACEHOLDER: 'Enter First Name',
    },
    {
      GRID_SIZE: 6,
      LABEL: 'Last Name',
      TYPE: 'string',
      ID: 'lastName',
      REQUIRED: true,
      PATTERN: /^([a-z]){0,255}$/i,
      MESSAGE_EMPTY: 'Please enter the last name',
      MESSAGE_INVALID: 'Invalid last name !',
      PLACEHOLDER: 'Enter Last Name',
    },
    {
      GRID_SIZE: 4,
      LABEL: 'User Access Start Date',
      TYPE: 'date',
      ID: 'userAccessStartDate',
      REQUIRED: true,
      // NOTE: eslint thinks there is the \ in regex is an escape character but it isn't.
      // eslint-disable-next-line no-useless-escape
      PATTERN: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12]\d|3[01])$/i,
      MESSAGE_EMPTY: 'Please enter the start date',
      MESSAGE_INVALID: 'Invalid start date !',
    },
    {
      GRID_SIZE: 6,
      LABEL: 'User Access End Date',
      TYPE: 'date',
      ID: 'userAccessEndDate',
      REQUIRED: true,
      // NOTE: eslint thinks there is the \ in regex is an escape character but it isn't.
      // eslint-disable-next-line no-useless-escape
      PATTERN: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12]\d|3[01])$/i,
      MESSAGE_EMPTY: 'Please enter the end date',
      MESSAGE_INVALID: 'Invalid Date, end date lesser than start date!',
    },
    {
      GRID_SIZE: 4,
      LABEL: 'Email ID',
      TYPE: 'email',
      ID: 'emailID',
      REQUIRED: true,
      PATTERN: /^(?=.{6,255}$)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i,
      MESSAGE_EMPTY: 'Please enter the email id',
      MESSAGE_INVALID: 'Invalid email id !',
      PLACEHOLDER: 'Enter Email Id',
    },
  ],
  INVALID_DATE_FORMAT: 'Invalid Date Format',
  LOADER_CAPTIONS: ['Searching User', 'Creating User', 'Updating User', 'Deleting User'],
};
export const ROLE_CONFIGURATION_CONSTANTS = {
  GLOBE: 'Globe',
  OPTIONS: 'Options',
  GLOBAL: 'Global',
  BU: 'BusinessUnit',
  PLANT: 'Plant',
  ADD_ROLE: 'Add Role',
  ERROR_MESSAGE_DROPDOWN: 'Please select an option',
  HEADER_TITLES: ['Edit Role', 'Create Role'],
  TEXT_BOX_CONSTANTS: [
    {
      GRID_SIZE: 4,
      LABEL: 'Role Name',
      TYPE: 'string',
      ID: 'roleName',
      REQUIRED: true,
      // NOTE: eslint thinks there is the \ in regex is an escape character but it isn't.
      PATTERN: /^([a-z0-9]){0,255}$/i,
      MESSAGE_EMPTY: 'Please enter the role name',
      MESSAGE_INVALID: 'Invalid role name !',
      PLACEHOLDER: 'Enter Role Name',
      MAX_LENGTH: 255,
    },
    {
      GRID_SIZE: 4,
      LABEL: 'Business Function',
      TYPE: 'string',
      ID: 'businessFunction',
      REQUIRED: true,
      PATTERN: null,
      MESSAGE_EMPTY: 'Please enter the business function',
      MESSAGE_INVALID: 'Invalid fusiness function !',
      PLACEHOLDER: 'Select Business Function',
    },
    {
      GRID_SIZE: 12,
      LABEL: 'Description',
      TYPE: 'string',
      ID: 'description',
      REQUIRED: false,
      // NOTE: eslint thinks there is the \ in regex is an escape character but it isn't.
      // eslint-disable-next-line no-useless-escape
      // PATTERN: /^([a-zA-Z0-9\s-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]){0,250}$/i,
      PATTERN: null,
      MESSAGE_EMPTY: 'Please enter the description',
      MESSAGE_INVALID: 'Invalid description !',
      PLACEHOLDER: 'Enter description here (Max char 250)',
      MAX_LENGTH: 250,
    },
  ],
  TABLE_HEADER_CONSTANTS: ['Level', 'Business Unit/Plant', 'Permissions', 'Landing Page'],
  TABLE_HEADER_KEYS: ['level', 'businessUnit', 'permission', 'landingPage'],
  TEXT_FIELD_CONSTANTS: ['Role Name', 'Business Function', 'Description'],
  TABLE_HEADER_PLACEHOLDERS: [
    'Select Level',
    'Select Business Unit/Plant',
    'Select Permissions',
    'Select Landing Page',
    'Select Business Function',
  ],
  LOADER_CAPTIONS: ['Searching Role', 'Creating Role', 'Updating Role', 'Deleting Role'],
};
export const MAX_INPUT_LENGTH=255;


export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const BUTTON_SIZE = {
	STANDARD: "standard",
	SMALL: "small",
};
