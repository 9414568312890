import { globalstate$, user, role } from '@smf/ui-util-app';

export async function getUserDetails(parameters) {
  const params = { isShortDesc: true, ...parameters };
  const data = globalstate$.value.factoryId
    ? { ...params, plantId: globalstate$.value.factoryId }
    : params;
  return user.getUserDetails(data);
}

export async function getUserDropdownData(params) {
  const data = globalstate$.value.factoryId
    ? { ...params, plantId: globalstate$.value.factoryId }
    : params;
  return role.getRoleByLevel({
    ...data,
    companyId: globalstate$.value.companyData?.companyId,
  });
}

export async function getUserDetailsById(userId, params) {
  const data = globalstate$.value.factoryId
    ? { ...params, plantId: globalstate$.value.factoryId }
    : params;
  return user.getUserById(userId, data);
}

export async function deleteUserFromConfig(userId) {
  const result = await user.deleteUser(userId, {});
  let response = {
    redirectTologin: false,
    data: result.data,
  };
  if (globalstate$.value?.userData?.sub === userId) {
    response.redirectTologin = true;
  }
  return response;
}

export async function updateUser(data) {
  console.log(' updateUser ', JSON.stringify(data));
  if (!data.emailId) throw new Error('Email Id missing ');
  const { firstName, lastName, userAccessEndDate, role: userRole, genciRoleId } = data;
  const mofifedData = {
    firstName,
    lastName,
    userAccessEndDate,
    role: userRole,
    initiatedBy: globalstate$.value?.userData?.email,
    genciRoleId,
  };
  return user.updateUser(data.emailId, mofifedData);
}

export async function createUser(data) {
  return user.createUser({
    ...data,
    initiatedBy: globalstate$.value?.userData?.email,
  });
}

export async function createRole(data) {
  return role.createRole(data);
}

export async function getRoleDetails(params) {
  const data = globalstate$.value.factoryId
    ? { ...params, plantId: globalstate$.value.factoryId }
    : params;
  return role.getRoleDetails(data);
}

export async function getRoleDetailsByName(roleName) {
  return role.getRoleByName(roleName);
}

export async function getRoleDetailsById(roleId) {
  return role.getRoleById(roleId);
}

export async function deleteRoleFromConfig(roleId) {
  return role.deleteRole(roleId);
}

export async function updateRole(data) {
  if (!data.roleId) throw new Error('Role Id missing ');
  return role.updateRole(data);
}

export async function getRoleDropDown(params) {
  const data = globalstate$.value.factoryId
    ? { ...params, plantId: globalstate$.value.factoryId }
    : params;
  return role.getRoleDropDown({
    ...data,
    companyId: globalstate$.value.companyData?.companyId,
  });
}
