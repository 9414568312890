import React from 'react';
import PropTypes from 'prop-types';
import { COMPONENT_ID } from '../../utils/componentId';
import { Tabs as MuiTabs ,Tab as MuiTab, styled } from '@mui/material';

const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  '&.MuiButtonBase-root.MuiTab-root':{
    minHeight: '32px',
  },
  '& .Mui-selected' : {
    border: `0.0625em solid ${theme.palette.background.default}`,
    borderRadius: '2px',
  }
}));

const StyledTab = styled(MuiTab)(({ theme }) => ({
  '&.MuiButtonBase-root.MuiTab-root': {
    padding: '0 0.75em',
    textTransform: 'none',
    minWidth: '224px',
    fontWeight: '700',
    fontSize: '16px',
    minHeight: '32px',
    opacity: '1',
    margin: '0',
    color: theme.palette.background.default,
  }
}));

function Tabs({ activeValue, textList, onChange, ...other }) {
  return (
    <StyledTabs
      data-testid={COMPONENT_ID.TABS}
      value={activeValue}
      onChange={onChange}
      TabIndicatorProps={{ style: { display: 'none' } }}
      {...other}
    >
      {textList.map(({ label, value, disabled = false }, index) => (
        <StyledTab
          key={`test__${index + 1}`}
          disabled={disabled}
          label={label}
          value={value}
          data-testid={`${COMPONENT_ID.TAB_ITEM}-${index}`}
          id={`${label}-${index}`}
        />
      ))}
    </StyledTabs>
  );
}

Tabs.propTypes = {
  textList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  activeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Tabs;
