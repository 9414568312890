// Library Imports
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import { Grid, TableCell, MenuItem, FormControl, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

// File Imports
import ErrorExclamationIcon from '../../assets/img/errorExclamation.svg';
import { USER_CONFIG_MESSAGES } from '../../constants/en-us';
import {
  RBAC_CONSTANTS,
  USER_CONFIGURATION_CONSTANTS,
  ACCESS_CONFIGURATION_CONSTANTS,
} from '../../constants';
import { convertDateToUTC, ternaryFunc, andCheck, getDropDownId } from '../../utils/helpers';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import GeneralDatepicker from '../../components/Datepicker';
import {
  StyledSelectbox,
  StyledTextBox,
  OuterGroup,
  InnerGroup,
  RadioCheck,
} from '../style';
/**
 * function to check whether its a onBlur event or not.
 */
const isBlurEvent = (e) => e === 'blur';

/////////////////////////////////
// MODULE : InputBoxGenerator  //
/////////////////////////////////

/*
  This react component is for Creating Form component in grid based on the value passed in props:
   - placeholder 			: Placeholder for the form Element 
   - gridSize    			: width of the form Component
   - label       			: Label to show
   - type        			: type can be date (for DatePicker component) and other for TextField
   - id          			: unique Identifier for form Element
   - required    			: true or flase 
   - pattern     			: regex validation on submit event
   - messageEmpty			: If required=true , Message to Display
   - messageInvalid 		: If regex validation fails, Message to Display
   - control                :  you can get this from useForm()
   - watch                  : Function you will get from useForm() 
   - selectedUserClickFlag  : true or false (Conditional flag)
*/
export const InputBoxGenerator = ({
  placeholder,
  gridSize,
  label,
  type,
  id,
  required,
  pattern,
  messageEmpty,
  messageInvalid,
  control,
  watch,
  selectedUserClickFlag,
}) => {
  const watchFields = watch();

  const [isStartDateInvalid, setIsStartDateInvalid] = useState(false);
  const [isEndDateInvalid, setIsEndDateInvalid] = useState(false);

  const dateValidator = (dateLabel, e = '') => {
    if (dateLabel === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[2]) {
      setIsStartDateInvalid(
        isBlurEvent(e)
          ? watchFields.userAccessStartDate &&
              !moment(watchFields.userAccessStartDate).isValid()
          : false
      );
    }
    if (dateLabel === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[3]) {
      setIsEndDateInvalid(
        isBlurEvent(e)
          ? watchFields.userAccessEndDate &&
              !moment(watchFields.userAccessEndDate).isValid()
          : false
      );
    }
  };

  const isDisabled =
    (label === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[4] ||
      label === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[2]) &&
    selectedUserClickFlag;

  const getDateErrorMessage = (fieldLabel) => {
    if (
      andCheck(
        andCheck(
          fieldLabel === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[2] ||
            fieldLabel === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[3],
          convertDateToUTC(
            moment(
              watchFields[
                ternaryFunc(
                  fieldLabel === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[2],
                  'userAccessStartDate',
                  'userAccessEndDate'
                )
              ]
            ).format('YYYY-MM-DD')
          ) < convertDateToUTC(moment(new Date()).format('YYYY-MM-DD'))
        ),
        !isDisabled
      )
    ) {
      return (
        <Typography
          sx={{
            color: (theme) => theme.customColors.white,
            fontSize: '0.75em',
            marginTop: '0.5em',
          }}
          varient="caption"
        >
          <span
            style={{
              display: 'inline-block',
              width: '2em',
              verticalAlign: 'top',
            }}
          >
            <ErrorExclamationIcon />
          </span>
          <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
            {USER_CONFIG_MESSAGES.ERROR_MESSAGE[4]}
          </span>
        </Typography>
      );
    }
    return null;
  };

  return (
    <Grid
      item
      xs={gridSize}
      sx={{
        ...(label === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[4]
          ? { marginBottom: '1.5em' }
          : { marginBottom: '3em' }),
      }}
    >
      <FormControl fullWidth variant="outlined">
        <Controller
          noRef
          name={id}
          render={({ field, fieldState: { error } }) => (
            <>
              <Typography sx={{ paddingBottom: '1.1875em' }} variant="subtitle2">
                {label}
                {ternaryFunc(
                  required,
                  <Typography
                    sx={{
                      display: 'inline',
                      color: (theme) => theme.palette.background.errorColor,
                      paddingLeft: '0.5em',
                    }}
                    data-testid={`asterix-${id}`}
                  >
                    {ACCESS_CONFIGURATION_CONSTANTS.ASTERIX}
                  </Typography>
                )}
              </Typography>
              {ternaryFunc(
                type === 'date',
                <GeneralDatepicker
                  disablePast={true}
                  label={label}
                  inputFormat="MM/DD/YYYY"
                  onChange={(value) => {
                    field.onChange(value);
                    dateValidator(label);
                  }}
                  renderInput={(params) => (
                    <StyledTextBox
                      {...params}
                      style={{ width: '13.1em' }}
                      onBlur={(e) => {
                        dateValidator(label, e.type);
                      }}
                      errorStyle={
                        (error && error.message) ||
                        andCheck(
                          (label === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[2],
                          isStartDateInvalid)
                        ) ||
                        andCheck(
                          label === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[3],
                          isEndDateInvalid ||
                            moment(watchFields.userAccessStartDate) >
                              moment(watchFields.userAccessEndDate)
                        )
                      }
                    />
                  )}
                  disabled={isDisabled}
                  {...field}
                />,
                <StyledTextBox
                  {...field}
                  id={id}
                  variant="outlined"
                  type={type}
                  errorStyle={andCheck(error, error?.message)}
                  placeholder={placeholder}
                  disabled={andCheck(
                    label === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[4] ||
                      label === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[2],
                    selectedUserClickFlag
                  )}
                />
              )}
              {ternaryFunc(
                error,
                <Typography
                  sx={{
                    color: (theme) => theme.customColors.white,
                    fontSize: '0.75em',
                    marginTop: '0.5em',
                  }}
                  varient="caption"
                >
                  <span
                    style={{
                      display: 'inline-block',
                      width: '2em',
                      verticalAlign: 'top',
                    }}
                  >
                    <ErrorExclamationIcon />
                  </span>
                  <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
                    {error?.message}
                  </span>
                </Typography>
              )}
              {ternaryFunc(
                andCheck(
                  label === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[2],
                  isStartDateInvalid
                ) ||
                  andCheck(
                    label === USER_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[3],
                    isEndDateInvalid
                  ),
                <Typography
                  sx={{
                    color: (theme) => theme.customColors.white,
                    fontSize: '0.75em',
                    marginTop: '0.5em',
                  }}
                  varient="caption"
                >
                  <span
                    style={{
                      display: 'inline-block',
                      width: '2em',
                      verticalAlign: 'top',
                    }}
                  >
                    <ErrorExclamationIcon />
                  </span>
                  <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
                    {USER_CONFIGURATION_CONSTANTS.INVALID_DATE_FORMAT}
                  </span>
                </Typography>
              )}
              {ternaryFunc(
                moment(watchFields.userAccessStartDate) >
                  moment(watchFields.userAccessEndDate),
                <Typography
                  sx={{
                    color: (theme) => theme.customColors.white,
                    fontSize: '0.75em',
                    marginTop: '0.5em',
                  }}
                  varient="caption"
                >
                  <span
                    sx={{
                      display: 'inline-block',
                      width: '2em',
                      verticalAlign: 'top',
                    }}
                  >
                    {ternaryFunc(
                      messageInvalid === USER_CONFIG_MESSAGES.ERROR_MESSAGE[1],
                      <ErrorExclamationIcon />
                    )}
                  </span>
                  <span
                    sx={{
                      display: 'inline-block',
                      width: 'calc(100% - 2em)',
                    }}
                  >
                    {ternaryFunc(
                      messageInvalid === USER_CONFIG_MESSAGES.ERROR_MESSAGE[1],
                      messageInvalid
                    )}
                  </span>
                </Typography>
              )}
              {getDateErrorMessage(label)}
            </>
          )}
          control={control}
          defaultValue={type === 'date' ? null : ''}
          rules={{
            required: required ? messageEmpty : false,
            pattern: {
              value: pattern,
              message: `${messageInvalid}`,
            },
          }}
        />
      </FormControl>
    </Grid>
  );
};

InputBoxGenerator.propTypes = {
  placeholder: PropTypes.string,
  gridSize: PropTypes.number,
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.instanceOf(RegExp),
  messageEmpty: PropTypes.string,
  messageInvalid: PropTypes.string,
  control: PropTypes.shape(),
  watch: PropTypes.func,
  selectedUserClickFlag: PropTypes.bool,
};

////////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////
// MODULE : TableCellGenerator //
/////////////////////////////////

/**
 * This is for generating Role Mapping UI for User
 */

export const TableCellGenerator = ({
  row,
  label,
  updateTableCellValuesForRow,
  index,
  isSubmitButtonClicked,
  isAnyEmptyFieldPresentInForm,
  required,
  getValues,
  userDropdownData,
  handlePlantChange,
  handleRoleChange,
  disableRoleOptions,
}) => {
  const formData = getValues();

  const { rxjsState } = useRxjsState();

  const isBUSystemAdmin = rxjsState.userData?.groups.includes(
    RBAC_CONSTANTS.ADMIN_ROLES.BUSINESS_UNIT
  );

  const [isStartDateInvalid, setIsStartDateInvalid] = useState(false);
  const [isEndDateInvalid, setIsEndDateInvalid] = useState(false);

  const dateValidator = (dateLabel, e = '') => {
    if (dateLabel === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[2]) {
      setIsStartDateInvalid(
        isBlurEvent(e) ? row.startDate != '' && !moment(row.startDate).isValid() : false
      );
    }
    if (dateLabel === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[3]) {
      setIsEndDateInvalid(
        isBlurEvent(e) ? row.endDate != '' && !moment(row.endDate).isValid() : false
      );
    }
  };

  const basicDateValidationCheck = (idx) =>
    andCheck(idx === 2, isStartDateInvalid) || andCheck(idx === 3, isEndDateInvalid);

  function returnErrCondition(idx) {
    let result = false;
    if (idx === 2 || idx === 3) {
      if (basicDateValidationCheck(idx)) {
        result = true;
      }
      if (
        andCheck(
          required,
          andCheck(
            row[USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]] === '',
            isSubmitButtonClicked || isAnyEmptyFieldPresentInForm
          )
        )
      ) {
        result = true;
      }
      if (
        andCheck(
          formData,
          andCheck(
            moment(row.startDate).format('YYYY-MM-DD') <
              moment(formData.userAccessStartDate).format('YYYY-MM-DD'),
            label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[2]
          )
        )
      ) {
        result = true;
      }
      if (
        formData &&
        andCheck(
          row.endDate,
          andCheck(
            moment(row.endDate).format('YYYY-MM-DD') >
              moment(formData.userAccessEndDate).format('YYYY-MM-DD'),
            label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[3]
          )
        )
      ) {
        result = true;
      }
      if (
        row.startDate &&
        row.endDate &&
        andCheck(
          moment(row.startDate) > moment(row.endDate),
          label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[3]
        )
      ) {
        result = true;
      }
    }
    return result;
  }

  const getInnerGroup = (subItem,category) => (
    <InnerGroup key={subItem.ID} value={getDropDownId(subItem.ID,category)}>
      {subItem.name }
    </InnerGroup>
  );
  return (
    <TableCell component="th" scope="row">
      <Typography variant="subtitle2" sx={{ paddingBottom: '0.75em', fontSize: '14px' }}>
        {label}
        {ternaryFunc(
          required,
          <Typography
            sx={{
              display: 'inline',
              color: (theme) => theme.palette.background.errorColor,
              paddingLeft: '0.5em',
            }}
          >
            {ACCESS_CONFIGURATION_CONSTANTS.ASTERIX}
          </Typography>
        )}
      </Typography>
      {ternaryFunc(
        label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[0] ||
          label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[1],
        <StyledSelectbox
          type="tableDropdown"
          placeholderStyle={!row[USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]]}
          errorStyle={andCheck(
            andCheck(
              required,
              row[USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]] === ''
            ),
            isSubmitButtonClicked || isAnyEmptyFieldPresentInForm
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            getContentAnchorEl: null,
          }}
          value={ternaryFunc(
            label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[1],
            row.roleID || 'none',
            row[USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]] || 'none'
          )}
          onChange={(e) => {
            if (label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[0]) {
              handlePlantChange(row[USER_CONFIGURATION_CONSTANTS.ROW_ID], e.target.value);
            } else {
              handleRoleChange(row[USER_CONFIGURATION_CONSTANTS.ROW_ID], e.target.value);
            }
          }}
        >
          <MenuItem value="none" disabled>
            {USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_PLACEHOLDERS[index]}
          </MenuItem>
          {ternaryFunc(
            label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[0], // GROUPED OPTIONS
            userDropdownData.map((item) =>
              // ? NOTE: if user is plant level system admin, display only plant Main label and his own plant underneath it.
              ternaryFunc(
                !!rxjsState.factoryId,
                [
                  item.name.toLowerCase() === 'plant' && (
                    <OuterGroup key={item.name} value={item.name} disabled>
                      {item.name}
                    </OuterGroup>
                  ),
                  item.name.toLowerCase() === 'plant' &&
                    item.child
                      .filter((childItem) => childItem.ID === rxjsState.factoryId)
                      .map((subItem) => getInnerGroup(subItem,item.name)),
                ],
                ternaryFunc(
                  andCheck(isBUSystemAdmin, !rxjsState.factoryId), // ? NOTE: if Business Unit System admin coming directly from globe page, don't display global level
                  [
                    item.name.toLowerCase() !== 'global' && (
                      <OuterGroup key={item.ID} value={item.ID} disabled>
                        {item.name}
                      </OuterGroup>
                    ),
                    item.name.toLowerCase() !== 'global' &&
                      item.child.map((subItem) => getInnerGroup(subItem,item.name)),
                  ],
                  [
                    <OuterGroup key={item.name} value={item.name} disabled>
                      {item.name}
                    </OuterGroup>,
                    item.child.map((subItem) => getInnerGroup(subItem,item.name)),
                  ]
                )
              )
            )
          )}
          {ternaryFunc(
            label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[1], // NON-GROUPED OPTIONS
            row[USER_CONFIGURATION_CONSTANTS.ROLE_LIST]
              .map((item) => item)
              .map((role) => (
                <MenuItem
                  key={role.role_id}
                  value={role.role_id}
                  disabled={disableRoleOptions(role.role_id, row.plant)}
                >
                  {role.name}
                </MenuItem>
              ))
          )}
        </StyledSelectbox>
      )}
      {ternaryFunc(
        label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[2] ||
          label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[3], // DATES
        <GeneralDatepicker
          disablePast={true}
          label={label}
          value={ternaryFunc(
            row[USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]] !== '',
            row[USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]]
          )}
          inputFormat="MM/DD/YYYY"
          onChange={(value) => {
            updateTableCellValuesForRow(
              row[USER_CONFIGURATION_CONSTANTS.ROW_ID],
              USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index],
              !value ? '' : moment(value).format('YYYY-MM-DD')
            );
            dateValidator(label);
          }}
          renderInput={(params) => (
            <StyledTextBox
              {...params}
              style={{ width: '13.1em' }}
              onBlur={(e) => {
                dateValidator(label, e.type);
              }}
              errorStyle={returnErrCondition(index)}
            />
          )}
        />
      )}
      {ternaryFunc(
        andCheck(
          label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[2],
          isStartDateInvalid
        ) ||
          andCheck(
            label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[3],
            isEndDateInvalid
          ),
        <Typography
          sx={{
            color: (theme) => theme.customColors.white,
            fontSize: '0.75em',
            marginTop: '0.5em',
          }}
          varient="caption"
        >
          <span style={{ display: 'inline-block', width: '2em', verticalAlign: 'top' }}>
            <ErrorExclamationIcon />
          </span>
          <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
            {USER_CONFIGURATION_CONSTANTS.INVALID_DATE_FORMAT}
          </span>
        </Typography>
      )}
      {ternaryFunc(
        andCheck(
          andCheck(
            andCheck(row.startDate, row.endDate),
            moment(row.startDate) > moment(row.endDate)
          ),
          label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[3]
        ),
        <Typography
          sx={{
            color: (theme) => theme.customColors.white,
            fontSize: '0.75em',
            marginTop: '0.5em',
          }}
          varient="caption"
        >
          <span style={{ display: 'inline-block', width: '2em', verticalAlign: 'top' }}>
            <ErrorExclamationIcon />
          </span>
          <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
            {USER_CONFIG_MESSAGES.ERROR_MESSAGE[1]}
          </span>
        </Typography>
      )}
      {label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[4] ||
      label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[5] ||
      label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[6] ? ( // AUTO FILL BOXES
        <Typography
          sx={{
            marginTop: '0.125em',
            fontSize: '14px',
            lineHeight: '19px',
            color: '#979797',
          }}
        >
          {ternaryFunc(
            row[USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]],
            row[USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]],
            '-'
          )}
        </Typography>
      ) : null}
      {ternaryFunc(
        label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[7],
        <RadioCheck
          checked={row[USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]]}
          onChange={(e) =>
            updateTableCellValuesForRow(
              row[USER_CONFIGURATION_CONSTANTS.ROW_ID],
              USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index],
              e.target.checked
            )
          }
          data-testid={`radio-btn`}
          inputProps={{ 'aria-label': 'radio' }}
        />
      )}
      {ternaryFunc(
        andCheck(
          formData,
          andCheck(
            moment(row.startDate).format('YYYY-MM-DD') <
              moment(formData.userAccessStartDate).format('YYYY-MM-DD'),
            label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[2]
          )
        ),
        <Typography
          sx={{
            color: (theme) => theme.customColors.white,
            fontSize: '0.75em',
            marginTop: '0.5em',
          }}
          varient="caption"
        >
          <span style={{ display: 'inline-block', width: '2em', verticalAlign: 'top' }}>
            <ErrorExclamationIcon />
          </span>
          <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
            {USER_CONFIG_MESSAGES.ERROR_MESSAGE[2]}
          </span>
        </Typography>
      )}
      {ternaryFunc(
        formData &&
          row.endDate &&
          !isEndDateInvalid &&
          andCheck(
            moment(row.endDate).format('YYYY-MM-DD') >
              moment(formData.userAccessEndDate).format('YYYY-MM-DD'),
            label === USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[3]
          ),
        <Grid item>
          <Typography
            varient="caption"
            sx={{
              color: (theme) => theme.customColors.white,
              maxWidth: '16em',
              fontSize: '0.75em',
              marginTop: '0.5em',
            }}
          >
            <span sx={{ display: 'inline-block', width: '2em', verticalAlign: 'top' }}>
              <ErrorExclamationIcon />
            </span>
            <span sx={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
              {USER_CONFIG_MESSAGES.ERROR_MESSAGE[3]}
            </span>
          </Typography>
        </Grid>
      )}
      {ternaryFunc(
        andCheck(
          required,
          andCheck(
            row[USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]] === '',
            isSubmitButtonClicked || isAnyEmptyFieldPresentInForm
          )
        ),
        <Typography
          sx={{
            color: (theme) => theme.customColors.white,
            fontSize: '0.75em',
            marginTop: '0.5em',
          }}
          varient="caption"
        >
          <span style={{ display: 'inline-block', width: '2em', verticalAlign: 'top' }}>
            <ErrorExclamationIcon />
          </span>
          <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
            {USER_CONFIG_MESSAGES.ERROR_MESSAGE[0]}
          </span>
        </Typography>
      )}
    </TableCell>
  );
};

TableCellGenerator.propTypes = {
  row: PropTypes.PropTypes.shape({
    rowID: PropTypes.number,
    plant: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    role: PropTypes.string,
    roleID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    function: PropTypes.string,
    permission: PropTypes.string,
    landingPage: PropTypes.string,
    default: PropTypes.bool,
  }),
  label: PropTypes.string,
  updateTableCellValuesForRow: PropTypes.func,
  index: PropTypes.number,
  isSubmitButtonClicked: PropTypes.bool,
  isAnyEmptyFieldPresentInForm: PropTypes.bool,
  required: PropTypes.bool,
  getValues: PropTypes.func,
  handlePlantChange: PropTypes.func,
  handleRoleChange: PropTypes.func,
  disableRoleOptions: PropTypes.func,
  userDropdownData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      child: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          child: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              role_id: PropTypes.number,
            })
          ),
        })
      ),
    })
  ),
};
