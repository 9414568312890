// Library Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import moment from 'moment';
import {
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  Typography,
  Fade,
  Modal,
  Backdrop,
  styled,
  MenuItem,
  Box,
} from '@mui/material';

// File Imports
import {
  InputBoxGenerator,
  TableCellGenerator,
} from '../../../modules/UserConfig/userConfigModules';
import AddButton from '../../../assets/img/PlusInCircle.svg';
import DeleteExclamation from '../../../assets/img/blueExclamation.svg';
import DeleteButton from '../../../assets/img/deleteBin.svg';
import SearchIcon from '../../../assets/img/searchIcon.svg';
import {
  queryConstants,
  USER_CONFIG_MESSAGES,
  genciRoleConfigConstants,
} from '../../../constants/en-us';
import {
  TOAST_REDUCER_CONSTANTS,
  USER_CONFIGURATION_CONSTANTS,
  ACCESS_CONFIGURATION_CONSTANTS,
  MAX_INPUT_LENGTH,
  BUTTON_TYPE,
} from '../../../constants';
import {
  createUser,
  updateUser,
  deleteUserFromConfig,
  getUserDetails,
  getUserDropdownData,
  getUserDetailsById,
  getRoleDetailsById,
  getRoleDropDown,
} from '../../../utils/apiHelper';
import {
  andCheck,
  convertDateToUTC,
  getDropDownId,
  ternaryFunc,
} from '../../../utils/helpers';
import { useToastContext } from '../../../context/toastContext';
import { escapeRegex } from '../../../modules/regex/regexRoles';
import {
  ModalPaper,
  SearchBoxGrid,
  SearchIconSpan,
  SectionGrid,
  StyledButtonContainer,
  StyledIconButton,
  StyledTableRow,
} from '../accessConfig';
import GeneralButton from '../../../components/Button';
import { StyledSelectbox } from '../../../modules/style';
import ErrorExclamationIcon from '../../../assets/img/errorExclamation.svg';

//Styled Component
const TableGridContainer = styled(Grid)(({ theme }) => {
  return {
    border: `1px solid ${theme.customColors.plantTableInnerRowGray}`,
    width: 'calc(100% + 2em)',
    maxWidth: 'calc(100% + 2em)',
    marginLeft: '-1em',
    marginTop: '-0.063em',
    minHeight: 'calc(100% - 25.750em)',
    padding: '1.25em',
    overflowX: 'auto',
    '& tr.MuiTableRow-root th:last-child': {
      minWidth: '15.375em',
      display: 'flex',
      alignItems: 'center',
    },
  };
});

// User Configuration : Role table State
export const INITIAL_TABLE_STATE = [
  {
    rowID: 0,
    plant: '',
    role: '',
    startDate: '',
    endDate: '',
    function: '',
    permission: '',
    landingPage: '',
    default: true,
    roleList: [],
    roleID: null,
    levelId: null,
    permissionId: null,
  },
];

// PARENT COMPONENT : UserConfig
const UserConfig = ({ genciRoles }) => {
  const { toastDispatch } = useToastContext();
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isDirty },
    getValues,
  } = useForm({ shouldFocusError: true });
  const [tableData, setTableData] = useState(INITIAL_TABLE_STATE);
  const [isTableDropdownChanged, setisTableDropdownChanged] = useState(false);
  const [isEditedtableData, setisEditedTableData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isToastEnabled, setIsToastEnabled] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedRoleID, setSelectedRoleID] = useState(null);
  const [selectedRowID, setSelectedRowID] = useState(null);
  const [userRolesList, setUserRolesList] = useState([]);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedUserClick, setSelectedUserClick] = useState(false);
  const [selectedGenciRole, setSelectedGenciRole] = useState(null);

  const queryClient = useQueryClient();

  // disable condition for save/update button
  const disableActionButton =
    (isEdit &&
      !isDirty &&
      isEditedtableData === tableData &&
      selectedGenciRole === searchResult?.genciRoleId) || // disable update button
    (!isEdit && !isDirty && !isTableDropdownChanged && !selectedGenciRole); // disable save button

  /**
   * Resetting the form values on mounting this component
   */
  useEffect(() => {
    reset({
      sub: null,
      firstName: '',
      lastName: '',
      userAccessStartDate: null,
      userAccessEndDate: null,
      emailID: '',
      genciRoleId: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  Reset Funtion  //
  const onReset = (
    searchUserDetailsInProgress,
    firstName,
    lastName,
    startDate,
    endDate,
    emailID,
    sub,
    isUpdate
  ) => {
    reset({
      sub: sub || null,
      firstName: firstName || '',
      lastName: lastName || '',
      userAccessStartDate: startDate || null,
      userAccessEndDate: endDate || null,
      emailID: emailID || '',
    });
    if (!isUpdate) {
      setSelectedGenciRole(null);
      setTableData(INITIAL_TABLE_STATE);
    }
    if (isEdit) {
      setIsEdit(false);
    }
    if (isSubmitButtonClicked) {
      setSelectedGenciRole(null);
      setIsSubmitButtonClicked(false);
    }
    setSelectedUserClick(false);
    if (searchUserDetailsInProgress) {
      queryClient.invalidateQueries(queryConstants.GET_USER_DETAILS);
    }
    setisTableDropdownChanged(false);
  };

  const handleGenciRoleChange = (e) => {
    setSelectedGenciRole(e.target.value);
  };

  // Fetch Suggestions //
  useQuery([queryConstants.GET_USER_DETAILS], async () => {
    const params = {};
    const data = await getUserDetails(params);
    setUserRolesList(data);
  });

  const enableToast = (type, message) => {
    toastDispatch({
      type: type,
      payload: { message: message },
    });
  };

  // Dropdown API Call : For level & Role Data
  const { data: userDropdownData } = useQuery(
    [queryConstants.GET_USER_CONFIG_DROPDOWNS_FOR_GENCI_ROLE],
    async () => {
      const data = await getUserDropdownData({});
      return data.allLevels;
    }
    // PROPS FOR API ERROR
  );

  // Action Function : Called Once User get Selected after search
  const setUserDataIntoFormAndTable = (userData) => {
    if (userData) {
      const tableDataToInsert = userData.userRole.map((item, idx) => {
        let roleListForPlant = [];
        userDropdownData.forEach((dropDownItem) => {
          dropDownItem.child.forEach((plant) => {
            if (plant.ID === item.value) {
              roleListForPlant = plant.child;
              item.value = getDropDownId(plant.ID, item.level);
            }
          });
        });

        return {
          rowID: idx,
          plant: item.value,
          userRoleId: item.userRoleId ?? null,
          role: item.role,
          startDate: moment(item.roleAssignmentStartDate).format('YYYY-MM-DD'),
          endDate: moment(item.roleAssignmentEndDate).format('YYYY-MM-DD'),
          function: item.businessFunction,
          permission: item.permission,
          landingPage: item.landingPage,
          default: item.isDefault,
          roleID: item.roleId,
          roleList: roleListForPlant || [],
          permissionId: item.permissionId,
          levelId: item.levelId,
        };
      });

      onReset(
        null,
        userData.firstName,
        userData.lastName,
        moment(userData.startDate).format('YYYY-MM-DD'),
        moment(userData.endDate).format('YYYY-MM-DD'),
        userData.emailId,
        userData.sub,
        true
      );
      setIsEdit(true);
      setisEditedTableData(tableDataToInsert);
      setTableData(tableDataToInsert);
      setSelectedUserClick(true);
      if (tableDataToInsert.length == 0) setTableData(INITIAL_TABLE_STATE);
    }
  };

  const handlePlaceHolderStyle = (selectedGenciRole, genciRoles) => {
    for (let role of genciRoles) {
      if (role.genciRoleId === selectedGenciRole) {
        return false;
      }
    }
    return true;
  };

  // For Setting user information to state after search :
  const { isFetching: isgetUserDetailsByIdFetching } = useQuery(
    [queryConstants.GET_USER_DETAILS_BY_ID, selectedUserId, selectedRowID],
    async () => {
      return getUserDetailsById(selectedUserId, {});
    },
    {
      enabled: !!selectedUserId,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (response) => {
        if (response && response[0] && response[0].userRole) {
          const modifiedResponse = response[0];
          const modifiedItem = modifiedResponse.userRole.map((item) => {
            if (item.level === 'Global') {
              return {
                ...item,
                value: 'Global',
              };
            } else if (item.level === 'Business Unit') {
              return {
                ...item,
                value: item.businessUnitId,
              };
            } else {
              return {
                ...item,
                value: item.plantId,
              };
            }
          });
          modifiedResponse.userRole = modifiedItem;

          let result = response[0];
          result['emailId'] = result['email'];
          setSelectedGenciRole(result.genciRoleId);
          setValue('');
          setSelectedUserId(null);
          setSearchResult(result);
          setUserDataIntoFormAndTable(response[0]);
        }
      },
      // OnERROR
    }
  );

  // Get Role Information with the help of RoleId
  useQuery(
    [queryConstants.GET_ROLE_DETAILS, selectedRoleID, selectedRowID],
    async () => {
      return getRoleDetailsById(selectedRoleID, {});
    },
    {
      enabled: !!selectedRoleID,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (response) => {
        let businessFunctionDisplayValue = null,
          permissionDisplayValue = null,
          landingPageDisplayValue = null,
          levelId = null,
          permissionId = null;
        if (response) {
          const { businessFunction, level, permission, landingPage } = response;
          businessFunctionDisplayValue = businessFunction?.displayValue || null;
          permissionDisplayValue = permission?.displayValue || null;
          landingPageDisplayValue = landingPage?.displayValue || null;
          levelId = level?.id || null;
          permissionId = permission?.id || null;
        }

        setSelectedRoleID(null);
        setSelectedRowID(null);
        let modifiedtableData = tableData;
        modifiedtableData = modifiedtableData.map((item) => {
          if (item.rowID === selectedRowID) {
            return {
              ...item,
              function: businessFunctionDisplayValue,
              permission: permissionDisplayValue,
              landingPage: landingPageDisplayValue,
              levelId,
              permissionId,
            };
          }
          return item;
        });
        setTableData(modifiedtableData);
      },
      // OnERROR
    }
  );

  // Create User
  const { mutate: createUserMutate, isLoading: iscreateUserLoading } = useMutation(
    (data) => createUser({ ...data }),
    {
      onSuccess: () => {
        onReset(true);
        setIsSubmitButtonClicked(false);
        enableToast(
          TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          USER_CONFIG_MESSAGES.CREATE_USER_SUCCESS
        );
      },
      onError: (error) => {
        setIsSubmitButtonClicked(false);
        if (error.response && error.response.data && error.response.data.message) {
          enableToast(
            TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            error.response.data.message
          );
        } else {
          enableToast(
            TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            USER_CONFIG_MESSAGES.CREATE_USER_ERROR
          );
        }
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  // Update User
  const { mutate: updateUserMutate, isLoading: isupdateUserLoading } = useMutation(
    (data) => updateUser(data),
    {
      onSuccess: () => {
        setIsEdit(false);
        onReset(true);
        setIsSubmitButtonClicked(false);
        enableToast(
          TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          USER_CONFIG_MESSAGES.UPDATE_USER_SUCCESS
        );
      },
      onError: (error) => {
        setIsSubmitButtonClicked(false);
        if (error.response && error.response.data && error.response.data.message) {
          enableToast(
            TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            error.response.data.message
          );
        } else {
          enableToast(
            TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            USER_CONFIG_MESSAGES.UPDATE_USER_ERROR
          );
        }
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  // Delete user
  const { mutate: deleteUserMutate, isLoading: isdeleteUserLoading } = useMutation(
    (data) => deleteUserFromConfig(data.email),
    {
      onSuccess: (data) => {
        setIsEdit(false);
        onReset(true);
        enableToast(
          TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          USER_CONFIG_MESSAGES.DELETE_USER_SUCCESS
        );
        setModalOpen(false);
        if (data.redirectTologin) {
          setTimeout(() => {
            window.location = '/logout';
          }, 3000);
        }
      },
      onError: (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          enableToast(
            TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            error.response.data.message
          );
        } else {
          enableToast(
            TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            USER_CONFIG_MESSAGES.DELETE_USER_ERROR
          );
        }
        setModalOpen(false);
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  // for handling toasts for the component
  useEffect(() => {
    if (isToastEnabled) {
      setTimeout(() => {
        setIsToastEnabled(false);
      }, 3000);
    }
  }, [isToastEnabled]);

  // to modify only user entered inputs from the table
  const updateTableCellValuesForRow = (rowID, label, newValue) => {
    let modifiedTabelData = tableData;
    setisTableDropdownChanged(true);
    // if we're changing default
    if (label === 'default' && newValue === true) {
      modifiedTabelData = modifiedTabelData.map((row) => {
        if (row.rowID === rowID) {
          return {
            ...row,
            default: true,
          };
        }
        return {
          ...row,
          default: false,
        };
      });
    } else {
      modifiedTabelData = modifiedTabelData.map((row) => {
        if (row.rowID === rowID) {
          return {
            ...row,
            [label]: newValue,
          };
        }
        return row;
      });
    }
    setTableData(modifiedTabelData);
  };

  // To add a new empty row from rolelist
  const addNewRole = () => {
    const currentTableState = [...tableData];
    currentTableState.push({
      rowID: tableData.length,
      plant: '',
      role: '',
      startDate: '',
      endDate: '',
      function: '',
      permission: '',
      landingPage: '',
      default: false,
      roleID: null,
      roleList: [],
    });
    setTableData(currentTableState);
  };

  // To delete a row from role list
  const deleteRole = (rowID, rowToDelete) => {
    let currentTableState = [...tableData];
    currentTableState.splice(rowID, 1);
    currentTableState = currentTableState.map((row, index) => ({
      ...row,
      rowID: index,
      default: index === 0 && rowToDelete.default ? true : row.default,
    }));
    setTableData(currentTableState);
  };

  //  functions to manipulate the tabledata. called inside onchange of table elements
  const handlePlantChange = (rowID, newValue) => {
    let roleListForPlant = [];
    setisTableDropdownChanged(true);
    userDropdownData.forEach((item) => {
      item.child.forEach((plant) => {
        const plantId = getDropDownId(plant.ID, item.name);
        if (plantId === newValue) {
          roleListForPlant = plant.child;
        }
      });
    });

    let modifiedtableData = tableData;
    modifiedtableData = modifiedtableData.map((item) => {
      if (item.rowID === rowID) {
        return {
          ...item,
          plant: newValue,
          role: '',
          roleID: null,
          roleList: roleListForPlant,
        };
      }
      return item;
    });
    setTableData(modifiedtableData);
  };

  // function to handle role change
  const handleRoleChange = (rowID, newValue) => {
    let roleIDToPass;
    // update tableData
    let modifiedtableData = tableData;
    modifiedtableData = modifiedtableData.map((item) => {
      if (item.rowID === rowID) {
        const roleItem = item.roleList.find((role) => role.role_id === newValue);
        roleIDToPass = roleItem.role_id;
        return {
          ...item,
          role: roleItem.name,
          roleID: roleIDToPass,
        };
      }
      return item;
    });
    setTableData(modifiedtableData);

    // this has been set to trigger the getRoledetails api
    setSelectedRowID(rowID);
    setSelectedRoleID(roleIDToPass);
  };

  // Data Validation before Save and Update
  const isDataValidated = (tableDataSet) => {
    // check only if user is being created.
    if (!isEdit) {
      if (
        tableDataSet.userAccessStartDate <
        convertDateToUTC(moment(new Date()).format('YYYY-MM-DD'))
      ) {
        return false;
      }
    }

    if (
      tableDataSet.userAccessEndDate <
      convertDateToUTC(moment(new Date()).format('YYYY-MM-DD'))
    ) {
      return false;
    }

    if (!selectedGenciRole) return false;

    const isValidated = [];
    tableDataSet.role.forEach((role) => {
      isValidated.push(
        !Number.isNaN(role.roleAssignmentStartDate) &&
          !Number.isNaN(role.roleAssignmentEndDate) &&
          role.roleId !== null
      );
    });
    return !isValidated.includes(false);
  };

  // On-Submit Funtion : Create and Update
  const onSubmit = (formData) => {
    setIsSubmitButtonClicked(true);
    let dataToSend = {
      ...formData,
      roles: tableData.filter((role) => role.plant !== INITIAL_TABLE_STATE[0].plant),
    };

    // modifying according to the post structure.
    let modifiedData = {
      firstName: dataToSend.firstName,
      lastName: dataToSend.lastName,
      userAccessStartDate: convertDateToUTC(
        moment(dataToSend.userAccessStartDate).format('YYYY-MM-DD')
      ),
      userAccessEndDate: convertDateToUTC(
        moment(dataToSend.userAccessEndDate).format('YYYY-MM-DD')
      ),
      emailId: dataToSend.emailID,
      genciRoleId: selectedGenciRole,
      role: dataToSend.roles.map((item) => ({
        roleId: item.roleID,
        levelId: item.levelId,
        permissionId: item.permissionId,
        permission: item.permission,
        roleAssignmentStartDate:
          convertDateToUTC(moment(item.startDate).format('YYYY-MM-DD')) >=
          convertDateToUTC(moment(dataToSend.userAccessStartDate).format('YYYY-MM-DD'))
            ? convertDateToUTC(moment(item.startDate).format('YYYY-MM-DD'))
            : NaN,
        roleAssignmentEndDate:
          convertDateToUTC(moment(dataToSend.userAccessEndDate).format('YYYY-MM-DD')) >=
            convertDateToUTC(moment(item.endDate).format('YYYY-MM-DD')) &&
          convertDateToUTC(moment(item.startDate).format('YYYY-MM-DD')) <=
            convertDateToUTC(moment(item.endDate).format('YYYY-MM-DD'))
            ? convertDateToUTC(moment(item.endDate).format('YYYY-MM-DD'))
            : NaN,
        isDefault: item.default,
      })),
    };

    if (modifiedData.role.length > 0 && isDataValidated(modifiedData) === true) {
      if (isEdit) {
        modifiedData['sub'] = dataToSend.sub;
        updateUserMutate(modifiedData);
      } else {
        createUserMutate(modifiedData);
      }
    }
  };

  //  ################################   search handler functions ##############################
  const onSuggestionSelected = (
    _event,
    // Extra params we can get from above method suggestionValue, suggestionIndex, sectionIndex, method
    { suggestion }
  ) => {
    if (suggestion && suggestion.email) {
      setSelectedUserId(suggestion.email);
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (_event, { newValue }) => {
    setValue(newValue);
  };

  const inputPropsAutoSuggest = {
    placeholder: USER_CONFIG_MESSAGES.SEARCH_USER,
    maxLength: MAX_INPUT_LENGTH,
    value,
    onChange,
  };

  const escapeRegexCharacters = (str) => str.replace(escapeRegex, '\\$&');

  const getSuggestions = (suggestionValue) => {
    const escapedValue = escapeRegexCharacters(suggestionValue.trim());
    if (escapedValue === '') {
      return [];
    }
    const regex = new RegExp(`${escapedValue}`, 'i');
    return userRolesList.filter(
      (userList) =>
        regex.test(userList.email) ||
        regex.test(userList.firstName + ' ' + userList.lastName)
    );
  };

  const getSuggestionValue = (suggestion) =>
    `${suggestion.firstName} ${suggestion.lastName}`;

  const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.firstName} ${suggestion.lastName}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <span>
        {parts.map((part, index) => {
          const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;
          return (
            <span className={className} key={`${index + part.text}`}>
              {part.text}
            </span>
          );
        })}
      </span>
    );
  };

  const onSuggestionsFetchRequested = (data) => {
    setSuggestions(getSuggestions(data.value));
  };
  // #########################################################################################

  const getLoaderCaption = () => {
    if (isgetUserDetailsByIdFetching)
      return USER_CONFIGURATION_CONSTANTS.LOADER_CAPTIONS[0];
    if (iscreateUserLoading) return USER_CONFIGURATION_CONSTANTS.LOADER_CAPTIONS[1];
    if (isupdateUserLoading) return USER_CONFIGURATION_CONSTANTS.LOADER_CAPTIONS[2];
    if (isdeleteUserLoading) return USER_CONFIGURATION_CONSTANTS.LOADER_CAPTIONS[3];
    return '';
  };

  const disableRoleOptions = (roleid, plant) => {
    // First find the requiredRows in table by filtering with BU/Plant selected option
    // Find the existingroleIDs that are selected in the pre-existing rows
    // Disable that particular rowID option in the upcoming rows

    const modifiedTabelData = tableData;
    const requiredRows = modifiedTabelData.filter((row) => row.plant === plant);
    const existingBusinessUnits = requiredRows.map((item) => item.roleID);

    return existingBusinessUnits.includes(roleid);
  };

  const isAnyEmptyFieldPresentInForm = Object.keys(errors).length > 0;

  return (
    <SectionGrid
      // container
      direction="column"
      alignItems="center"
      justify="flex-start"
    >
      {/* Modal */}
      <Modal
        aria-labelledby="transition-modal-user-search"
        aria-describedby="transition-modal-user-search"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <ModalPaper
            container
            item
            direction="column"
            alignItems="flex-start"
            xs={4}
            spacing={2}
          >
            {/* Contents */}
            <Grid sx={{ display: 'flex', padding: '1.5em' }}>
              <Grid xs={1} item sx={{ marginRight: '0.75em' }}>
                <DeleteExclamation />
              </Grid>
              <Grid
                container
                item
                xs={10}
                direction="column"
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5">Confirm!</Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    fontWeight: 'normal',
                    fontSize: '.875em',
                    lineHeight: '1.188em',
                    marginBottom: '2.188em',
                    marginTop: '1em',
                  }}
                >
                  {USER_CONFIG_MESSAGES.DELETE_USER_DESCRIPTION_1}
                </Grid>
                <Grid
                  item
                  sx={{ fontWeight: 'bold', fontSize: '.875em', lineHeight: '1.188em' }}
                >
                  {' '}
                  {`${USER_CONFIG_MESSAGES.DELETE_USER_DESCRIPTION_2}-${
                    searchResult
                      ? `${searchResult.firstName} ${searchResult.lastName}`
                      : ''
                  }?`}
                </Grid>
              </Grid>
            </Grid>

            <Grid item container direction="row" justify="flex-end">
              <GeneralButton
                type={BUTTON_TYPE.SECONDARY}
                onClick={() => setModalOpen(false)}
                disabled={isdeleteUserLoading}
              >
                {ACCESS_CONFIGURATION_CONSTANTS.DELETE_BUTTON_LABLES.NO}
              </GeneralButton>
              <GeneralButton
                onClick={() =>
                  deleteUserMutate({
                    email: searchResult.email,
                  })
                }
                disabled={isdeleteUserLoading}
              >
                {ACCESS_CONFIGURATION_CONSTANTS.DELETE_BUTTON_LABLES.YES}
              </GeneralButton>
            </Grid>
          </ModalPaper>
        </Fade>
      </Modal>

      {/* Form */}
      <form sx={{ width: '100%', height: '100%' }}>
        <Grid
          container
          sx={{
            display: 'block',
            padding: '1.5em 2em 0 2em',
            color: (theme) => theme.customColors.greyishWhite,
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h5">
                  {isEdit
                    ? USER_CONFIGURATION_CONSTANTS.HEADER_TITLES[0]
                    : USER_CONFIGURATION_CONSTANTS.HEADER_TITLES[1]}
                </Typography>
              </Grid>
              <SearchBoxGrid key="searchBar" item xs={4}>
                <SearchIconSpan>
                  <SearchIcon />
                </SearchIconSpan>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionSelected={onSuggestionSelected}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputPropsAutoSuggest}
                />
              </SearchBoxGrid>
            </Grid>
            <Grid container>
              <input type="hidden" {...register(`sub`)} defaultValue={null} />
              {USER_CONFIGURATION_CONSTANTS.TEXT_BOX_CONSTANTS.map((item) => (
                <InputBoxGenerator
                  placeholder={item.PLACEHOLDER}
                  key={item.ID}
                  gridSize={item.GRID_SIZE}
                  label={item.LABEL}
                  type={item.TYPE}
                  id={item.ID}
                  required={item.REQUIRED}
                  pattern={item.PATTERN}
                  messageEmpty={item.MESSAGE_EMPTY}
                  messageInvalid={item.MESSAGE_INVALID}
                  control={control}
                  watch={() => watch()}
                  selectedUserClickFlag={selectedUserClick}
                />
              ))}
              <Box>
                <Typography sx={{ paddingBottom: '1.1875em' }} variant="subtitle2">
                  {genciRoleConfigConstants.GENCI_ROLE}
                  <Typography
                    sx={{
                      display: 'inline',
                      color: (theme) => theme.palette.background.errorColor,
                      paddingLeft: '0.5em',
                    }}
                  >
                    {ACCESS_CONFIGURATION_CONSTANTS.ASTERIX}
                  </Typography>
                </Typography>
                <StyledSelectbox
                  type="dropdown"
                  label="Select GenCI Role"
                  data-testid="genciRoleDropdown"
                  errorStyle={
                    selectedGenciRole === null &&
                    (isSubmitButtonClicked || isAnyEmptyFieldPresentInForm)
                  }
                  placeholderStyle={handlePlaceHolderStyle(selectedGenciRole, genciRoles)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    getContentAnchorEl: null,
                  }}
                  value={ternaryFunc(
                    selectedGenciRole !== null,
                    selectedGenciRole,
                    'none'
                  )}
                  onChange={(e) => {
                    handleGenciRoleChange(e);
                  }}
                >
                  <MenuItem value="none" disabled>
                    {genciRoleConfigConstants.SELECT_ROLE}
                  </MenuItem>
                  {genciRoles?.map((role) => (
                    <MenuItem
                      key={role.genciRoleId}
                      value={role.genciRoleId}
                      disabled={role.genciRoleId === searchResult?.genciRoleId}
                    >
                      {role.genciRoleName}
                    </MenuItem>
                  ))}
                </StyledSelectbox>
                {andCheck(
                  selectedGenciRole === null &&
                    (isSubmitButtonClicked || isAnyEmptyFieldPresentInForm),
                  <Typography
                    sx={{
                      color: (theme) => theme.customColors.white,
                      fontSize: '0.75em',
                      marginTop: '0.5em',
                    }}
                    varient="caption"
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        width: '2em',
                        verticalAlign: 'top',
                      }}
                    >
                      <ErrorExclamationIcon />
                    </span>
                    <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
                      {USER_CONFIG_MESSAGES.ERROR_MESSAGE[0]}
                    </span>
                  </Typography>
                )}
              </Box>
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: 'end',
                  paddingRight: '2em',
                  marginBottom: '0.5em',
                }}
              >
                <Typography
                  sx={{
                    display: 'inline',
                    color: (theme) => theme.palette.background.errorColor,
                    paddingRight: '1em',
                  }}
                >
                  {ACCESS_CONFIGURATION_CONSTANTS.ASTERIX}
                </Typography>
                <Typography variant="caption">
                  {ACCESS_CONFIGURATION_CONSTANTS.MANDATORY_FIELDS}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Table */}
          <TableGridContainer item xs={12}>
            <Table aria-label="simple table">
              <TableBody>
                {tableData.map((row, rowIndex) => (
                  <StyledTableRow key={row.rowID}>
                    {USER_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS.map(
                      (header, index) => (
                        <TableCellGenerator
                          key={header}
                          row={row}
                          label={header}
                          updateTableCellValuesForRow={updateTableCellValuesForRow}
                          index={index}
                          isSubmitButtonClicked={isSubmitButtonClicked}
                          isAnyEmptyFieldPresentInForm={isAnyEmptyFieldPresentInForm}
                          required={
                            !!(index === 0 || index === 1 || index === 2 || index === 3)
                          }
                          getValues={getValues}
                          userDropdownData={userDropdownData || []}
                          handlePlantChange={handlePlantChange}
                          handleRoleChange={handleRoleChange}
                          disableRoleOptions={disableRoleOptions}
                        />
                      )
                    )}

                    <TableCell component="th" scope="row">
                      <Typography
                        variant="subtitle2"
                        sx={{
                          paddingBottom: '0.75em',
                          opacity: '0.5',
                          fontSize: '14px',
                        }}
                      >
                        &nbsp;
                      </Typography>
                      <StyledIconButton
                        disabled={tableData.length < 2}
                        aria-label="delete"
                        size="small"
                        onClick={() => deleteRole(row.rowID, row)}
                      >
                        <DeleteButton data-testid={`${'dlt-btn'}-${rowIndex}`} />
                      </StyledIconButton>

                      {rowIndex === tableData.length - 1 ? (
                        <IconButton
                          aria-label="add"
                          size="small"
                          onClick={addNewRole}
                          sx={{
                            paddingLeft: '1.25em',
                            color: (theme) => theme.customColors.white,
                          }}
                        >
                          <AddButton />
                          <Typography sx={{ paddingLeft: '0.625em' }}>
                            {USER_CONFIGURATION_CONSTANTS.ADD_ROLE}
                          </Typography>
                        </IconButton>
                      ) : null}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableGridContainer>
        </Grid>
        <Grid container>
          <StyledButtonContainer xs={12} item>
            <Grid item>
              {isEdit ? (
                <GeneralButton
                  type={BUTTON_TYPE.SECONDARY}
                  onClick={() => onReset()}
                  variant="outlined"
                >
                  {ACCESS_CONFIGURATION_CONSTANTS.BUTTON_NAME[3]}
                </GeneralButton>
              ) : (
                <GeneralButton
                  type={BUTTON_TYPE.SECONDARY}
                  disabled={!isDirty && !isTableDropdownChanged}
                  onClick={() => onReset()}
                  variant="outlined"
                >
                  {ACCESS_CONFIGURATION_CONSTANTS.BUTTON_NAME[1]}
                </GeneralButton>
              )}
              {isEdit ? (
                <GeneralButton
                  type={BUTTON_TYPE.SECONDARY}
                  onClick={() => setModalOpen(true)}
                  variant="outlined"
                >
                  {ACCESS_CONFIGURATION_CONSTANTS.BUTTON_NAME[4]}
                </GeneralButton>
              ) : null}
              <GeneralButton
                type={BUTTON_TYPE.PRIMARY}
                variant="outlined"
                onClick={handleSubmit(onSubmit)}
                disabled={disableActionButton}
              >
                {isEdit
                  ? ACCESS_CONFIGURATION_CONSTANTS.BUTTON_NAME[2]
                  : ACCESS_CONFIGURATION_CONSTANTS.BUTTON_NAME[0]}
              </GeneralButton>
            </Grid>
            {isgetUserDetailsByIdFetching ||
            isdeleteUserLoading ||
            isupdateUserLoading ||
            iscreateUserLoading ? (
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size="1em" color="inherit" />
                <Typography
                  variant="subtitle1"
                  sx={{ paddingLeft: '1em', paddingRight: '0.5em' }}
                >
                  {getLoaderCaption()}
                </Typography>
              </Grid>
            ) : null}
          </StyledButtonContainer>
        </Grid>
      </form>
    </SectionGrid>
  );
};

UserConfig.propTypes = {
  genciRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default UserConfig;
