import { ThemeProvider } from '@mui/material';
import { theme } from '@smf/ui-util-app';
import { ToastProvider } from './context/toastContext';
import ReactQueryProvider from './context/react-query.provider';
import Routes from './containers/Routes';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useRxjsState } from './utils/hooks/useRxjsState';

export default function Root() {
  const { rxjsState } = useRxjsState();
  return (
    <ReactQueryProvider>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <ToastProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Routes isLoggedInUser={rxjsState.isLoggedInUser} />
            </LocalizationProvider>
          </ToastProvider>
        </StylesProvider>
      </ThemeProvider>
    </ReactQueryProvider>
  );
}
