import React from "react";
import PropTypes from "prop-types";
import { Slide, Snackbar, styled, Typography, Box, alpha } from "@mui/material";
import { TOAST_TYPE } from "../../constants";
import { USER_CONFIG_MESSAGES as MESSAGE_STRINGS } from "../../constants/en-us";
import ErrorIcon  from "../../assets/img/dangerIconV2.svg";
import WarningIcon  from "../../assets/img/warningIconV2.svg";
import SuccessIcon  from "../../assets/img/successIconV2.svg";
import InfoIcon  from "../../assets/img/infoIconV2.svg";

const bgColors = {
	[TOAST_TYPE.ERROR]: "cocoaToneRed",
	[TOAST_TYPE.WARNING]: "bronzeTone",
	[TOAST_TYPE.SUCCESS]: "blackOliveGreen",
	[TOAST_TYPE.INFO]: "nileBlue",
};

const borderColors = {
	[TOAST_TYPE.ERROR]: "lavaRed",
	[TOAST_TYPE.WARNING]: "tangerineColor",
	[TOAST_TYPE.SUCCESS]: "saveGreen",
	[TOAST_TYPE.INFO]: "highlightBlue",
};
const toastIcons = {
	[TOAST_TYPE.ERROR]: () => <ErrorIcon height={32} width={32} data-testid={`toast-icon-error`}/>,
	[TOAST_TYPE.WARNING]: () => <WarningIcon height={32} width={32} data-testid={`toast-icon-warning`} />,
	[TOAST_TYPE.SUCCESS]: () => <SuccessIcon height={32} width={32} data-testid={`toast-icon-success`} />,
	[TOAST_TYPE.INFO]: () => <InfoIcon height={32} width={32} data-testid={`toast-icon-info`}/>,
};
function SnackBarContent({ type = TOAST_TYPE.SUCCESS, message = "" }) {
	const Icon = toastIcons[type] || toastIcons[TOAST_TYPE.SUCCESS];
	return (
		<Box
			display="flex"
			justify="center"
			alignItems="center"
			minHeight={64}
			marginX="1rem"
		>
			<Icon />
			<Box marginLeft="1rem">
				<Typography variant="subtitle1" data-testid="toast-content">
					{message || MESSAGE_STRINGS[`Toast.message.${type}`]}
				</Typography>
			</Box>
		</Box>
	);
}
SnackBarContent.propTypes = {
	type: PropTypes.string,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
const CustomizedToast = styled(Snackbar, {
	shouldForwardProp: (prop) => prop !== "type",
})(({ theme, type }) => {
	const bgColor = bgColors[type] || bgColors[TOAST_TYPE.SUCCESS];
	const borderColor = borderColors[type] || borderColors[TOAST_TYPE.SUCCESS];
	return {
		zIndex: theme.zIndex.modal + 1,
		".MuiSnackbarContent-root": {
			minWidth: "28.5rem",
			background: alpha(theme.palette.background[bgColor], 0.95),
			border: `0.0625rem solid ${theme.palette.border[borderColor]}`,
			borderRadius: "0.25rem",
			padding: 0,
		},
		".MuiSnackbarContent-message": { width: "100%", padding: 0 },
	};
});
function Toast({
	open = false,
	type = TOAST_TYPE.SUCCESS,
	message = MESSAGE_STRINGS["Toast.message.SUCCESS"],
	vertical = "bottom",
	horizontal = "center",
	direction = "right",
	...others
}) {
	const SlideTransitionComponent = React.memo(function SlideTransition(props) {
		return <Slide {...props} direction={direction} />;
	});
	return (
		<CustomizedToast
			type={type}
			anchorOrigin={{ vertical, horizontal }}
			open={open}
			TransitionComponent={SlideTransitionComponent}
			data-testid={`toast-${type}`}
			message={<SnackBarContent message={message} type={type} />}
			{...others}
		/>
	);
}

Toast.propTypes = {
	open: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	type: PropTypes.string,
	vertical: PropTypes.string,
	horizontal: PropTypes.string,
	direction: PropTypes.string,
};

export default Toast;
