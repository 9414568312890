export const SCHEDULE_ID = {
  SHIFT_START_TIME: 'shift-start-time-field',
  SHIFT_END_TIME: 'shift-end-time-field',
  SHIFT_NAME: 'shift-name-field',
  SHIFT_CREATE: 'create-shift-button',
  SHIFT_DELETE: 'delete-shift-button',
  PRODUCTION_DAY_START: 'production-day-start-time',
  PRODUCTION_DAY_END: 'production-day-end-time',
  PRODUCTION_DAY_TIMEZONE: 'production-day-timezone',
  TIMEZONE_ELEMENTS: 'timezone-elements',
};

export const HEADER_ID = {
  CONFIG_BUTTON: 'button-config',
};

export const CONFIG_SIDEBAR_ID = {
  SELECT_PLANT: 'searchDropdown-selectPlant',
  CREATE_PLANT: 'create-plant',
};

export const CREWS_ID = {
  CREW_NAME: 'textfield-crewName',
  CREW_DELETE: 'button-crewDelete',
  CREW_ADD: 'button-crewAdd',
};

export const ROTATION_ID = {
  ROTATION_TABLE: 'table-rotation',
  CREW_DROPDOWN: 'dropdown-crew-',
  ROTATION_ADD: 'button-addRotation',
  ROTATION_DELETE: 'button-deleteRotation',
  CREW_ITEMS: 'crew-items',
  CREW_ERROR: 'crew-error',
  ROTATION_HEADER: 'header-rotationName',
  WEEKNAME: 'weekName-value',
  WEEK: {
    MONDAY: 'mon',
    TUESDAY: 'tue',
    WEDNESDAY: 'wed',
    THURSDAY: 'thu',
    FRIDAY: 'fri',
    SATURDAY: 'sat',
    SUNDAY: 'sun',
  },
};
export const COMPONENT_ID = {
  STEPPER: 'step-',
  TABLE_HEADER_ROW: 'table-header-row',
  TABLE_CELL_ROW: 'table-cell-row',
  MODAL_BODY: 'modal-body',
  MODAL_BUTTON_PRIMARY: 'button-modalPrimary',
  MODAL_BUTTON_SECONDARY: 'button-modalSecondary',
  TABS: 'tabs',
  TAB_ITEM: 'tab-item',
  PAGE_SIDEBAR_ITEM: 'sidebar-menu-item',
  MAIN_SIDEBAR_ITEM: 'mainSidebar-menu-item',
  MAIN_SIDEBAR_LIST: 'mainSidebar-list',
  CUSTOM_CHECKBOX: 'custom-checkbox',
  ICON_BUTTON: 'icon-button',
};

export const PLANT_FINANCIAL_ID = {
  SOLD_OUT_DROPDOWN: 'dropdown-soldout',
  SOLD_OUT_ITEMS: 'soldout-items',
  UOM_DROPDOWN: 'dropdown-uom',
  UOM_ITEMS: 'uom-items',
  CONTRIBUTION_MARGIN: 'box-contributionMargin',
  VARIABLE_COST: 'box-variableCost',
  UOM_VALUE: 'text-uom',
  TOTAL_COST: 'text-variableCost',
  COST_TEXTFIELD: 'textfield-cost',
};

export const CONFIG_COMPLETE_ID = {
  EDIT_BUTTON: 'button-configEdit',
  START_CONFIG_LINK: 'link-config',
};

export const FACTORY_HEALTH_ID = {
  FACTORY_HEALTH_SELECTION: 'dropdown-factoryHealth',
  FACTORY_HEALTH_ITEMS: 'factoryHealth-items',
  TARGETS_ACCORDION: 'targets-accordion',
  TARGETS_SUMMARY: 'targets-summary',
  ACTUALS_ACCORDION: 'actuals-accordion',
  ACTUALS_SUMMARY: 'actuals-summary',
  MAXIMUM_CAPACITY_ACCORDION: 'maximum-capacity-accordion',
  MAXIMUM_CAPACITY_SUMMARY: 'maximum-capacity-summary',
  DEVIATION_THRESHOLD_ACCORDION: 'deviation-threshold-accordion',
  DEVIATION_THRESHOLD_SUMMARY: 'deviation-threshold-summary',
};

export const ACTUALS_ID = {
  TIME_RANGE_DROPDOWN: 'dropdown-timerange',
  UOM_DROPDOWN: 'dropdown-uom',
  TIME_RANGE: 'text-timerange',
  TIME_RANGE_ITEMS: 'timerange-items',
  UOM_ITEMS: 'uom-items',
  REFRESH_RATE_TEXTFIELD: 'textfield-refreshRate',
  SAVE: 'actuals-save',
  SUMMARY: 'actuals-summary',
  ACCORDION: 'actuals-accordion',
  REFRESH_RATE_DROPDOWN: 'dropdown-refreshRate',
};

export const FH_TARGETS_ID = {
  EXPORT_DATA_BUTTON: 'export-data-button',
  UPLOAD_CSV_BUTTON: 'upload-csv-button',
  UPLOAD_CSV_DROPZONE: 'upload-csv-dropzone',
};

export const DEVIATION_THRESHOLD_ID = {
  DIRECTION_DROPDOWN: 'dropdown-direction',
  TYPE_DROPDOWN: 'dropdown-type',
  VALUE: 'deviation-value',
  LOWER_BOUND_TEXTFIELD: 'textfield-lowerbound',
  UPPER_BOUND_TEXTFIELD: 'textfield-upperbound',
  SAVE: 'deviation-save',
  DROPDOWN_ITEMS: 'dropdown-deviation-items',
  ROW: 'grid-deviation-row',
};

export const OEE_CONFIG_ID = {
  OEE_SELECTION: 'dropdown-oee',
  OEE_ITEMS: 'oee-items',
};

export const TARGET_ID = {
  ACCORDION: 'accordion-targets',
  SUMMARY: 'targets-summary',
  SAVE: 'targets-save',
  TEXTFIELD: 'textfield-targetValue',
  UOM_DROPDOWN: 'dropdown-targetUOM',
  DROPDOWN_ITEMS: 'target-dropdown-items',
  UOM_TEXT: 'text-targetUOM',
  LINE_DROPDOWN: 'dropdown-target-line',
  ASSET_DROPDOWN: 'dropdown-target-assets',
  ENTITY_NAME: 'text-entityName',
  GRID_ROW: 'grid-row',
};

export const UPLOAD_COMPONENT_ID = {
  EXPORT_BUTTON: 'button-export',
  UPLOAD_BUTTON: 'button-upload',
  SECONDARY_UPLOAD_BUTTON: 'secondary-upload-button',
  TEMPLATE_DOWNLOAD: 'button-template-download',
  EXPORT_CSV_DATA: 'export-csv-data',
};

export const WEEKLY_ROTATIONS_ID = {
  UPLOAD_CSV_BUTTON: 'button-csv-upload',
  EXPORT_DATA_BUTTON: 'button-export-data',
  APPOINTMENT_BAR: 'bar-appointment',
  DAY_CELL: 'day-cell',
  CLOSE_BUTTON: 'button-close',
  SAVE_BUTTON: 'button_save',
  APPOINTMENT_DROPDOWN: 'dropdown-appointment',
  APPOINTMENT_LAYOUT: 'layout-appointmentForm',
  APPOINTMENT_TOOLTIP_LAYOUT: 'layout-tooltip',
  TOOLTIP_HEADER: 'header-tooltip',
  LEGEND: 'grid-legend',
  SWITCH: 'grid-switch',
  PREVIOUS_MONTH_BUTTON: 'button-previousMonth',
  NEXT_MONTH_BUTTON: 'button-nextMonth',
  TOOLBAR_LAYOUT: 'layout-toolbar',
};

export const IDEAL_RATE_TEST_ID = {
  UOM_DROPDOWN: 'dropdown-runRate-uom',
  RUN_RATE_TEXTFIELD: 'textfield-runRate',
  LABEL: 'label-runRate',
  DROPDOWN_ITEMS: 'uom-dropdown-items',
};
