import { performanceFontStyles } from '@smf/ui-util-app';
import {
  styled,
  TextField,
  Select,
  MenuItem,
  Radio,
  Tooltip
} from '@mui/material';

//Styled Components
const StyledSelectbox = styled(Select, {
	shouldForwardProp: (prop) => prop !== "type" && prop !== "errorStyle" && prop !== "placeholderStyle",
})(({ theme, type, errorStyle, placeholderStyle }) => {
	return {
    width: (type==='tableDropdown') ? '10em' : '16.1875em',
    height: '2em',
    borderRadius: '1.25em',
    border: `1px solid ${theme.palette.border.lightWhiteGrey}`,
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '1em',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      color: '#AAA',
      WebkitTextFillColor: '#AAA',
    },
    '.MuiSelect-icon.Mui-disabled':  {
      color: '#AAA',
    },
    ...(errorStyle && {borderColor : theme.palette.background.errorColor}),
    ...(placeholderStyle && { color: theme.palette.text.nobel }),
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
	};
});

const StyledTextBox = styled(TextField, {
	shouldForwardProp: (prop) => prop !== "errorStyle",
})(({ theme, errorStyle, multiline }) => {
	return {
    ...(!multiline && {width: '16.1875em'}),
    ...(multiline && {marginBottom: '1.25em',}),
    height: multiline ? '10em' : '2em',
    overflow: multiline ? 'auto' : 'none',
    border: `1px solid ${errorStyle ? '#DA291C' : theme.customColors.greyishBlue}`,
    borderRadius: '0.25em',
    '& .MuiOutlinedInput-input': {
      padding: '0.1875em 0.875em',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      color: '#AAA',
      WebkitTextFillColor: '#AAA',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
    {
      border: 'none'
    },
    '& .MuiIconButton-root': {
      color: 'white'
    },
	};
});

const OuterGroup = styled(MenuItem)(({ theme }) => {
  return {
    color: theme.customColors.white,
    fontWeight: performanceFontStyles.heavyFont,
    paddingLeft: '0.5em',
    '&.MuiListItem-root.Mui-disabled': {
      opacity: 'unset',
    },
  };
});

const InnerGroup = styled(MenuItem)(({ _theme }) => {
  return {
    paddingLeft: '3em!important',
  };
});

const RadioCheck = styled(Radio)(({ theme }) => {
  return {
    height: '1.25em',
    color : theme.customColors.white,
    '& .MuiSvgIcon-root.PrivateRadioButtonIcon-layer': {
      color: theme.palette.text.greenText,
    },
    '&:checked svg:last-child': {
      color: '#86bc25',
    },
    '& svg:last-child': {
      color: '#86bc25',
    },
  };
});


const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

const CustomTooltip = styled(ToBeStyledTooltip)(({ theme }) => {
  return {
    whiteSpace: 'pre-line',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.customColors.eerieBlack,
    color: 'white',
    maxWidth: '677px',
    maxHeight: 180,
    padding: '1.75em 2em ',
  };
});


export { StyledSelectbox, StyledTextBox, OuterGroup, InnerGroup, RadioCheck, CustomTooltip };