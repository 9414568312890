export function generatePermissionKeys(permissions = []) {
  return permissions.map((permissionArr) => permissionArr.join());
}

export const convertDateToUTC = (dateToConvert) => {
  const date = new Date(dateToConvert);
  const localTime = date.getTime();
  const offset = date.getTimezoneOffset() * 60 * 1000;
  const utcTime = localTime + offset;
  const customDate = new Date(utcTime);
  const currentDateandTime = new Date();
  const time =
    currentDateandTime.getHours() * 60 * 60 * 1000 +
    currentDateandTime.getMinutes() * 60 * 1000 +
    currentDateandTime.getSeconds() * 1000;
  return customDate.getTime() + time;
};

export const isRBACAccessForUserPresent = (
  userInformation,
  levelAndPermissionCombinations = []
) => {
  const currentUsersLevelAndPermission = [
    userInformation[0]?.userRole[0]?.level,
    userInformation[0]?.userRole[0]?.permission,
  ].join();

  const allowedPermissionList = generatePermissionKeys(levelAndPermissionCombinations);

  return allowedPermissionList.includes(currentUsersLevelAndPermission);
};

export const ternaryFunc = (condition, positiveCase, negativeCase = null) =>
  condition ? positiveCase : negativeCase;

export const andCheck = (param1, param2) => param1 && param2;

export const getDropDownId = (id, level) => {
  return level?.toLowerCase() === 'plant' ? 'P-' + id : id;
};
