import React, { useState } from 'react';
import { Box, Grid, Typography, styled, TableRow, IconButton } from '@mui/material';
import { ACCESS_CONFIGURATION_CONSTANTS } from '../../constants';
import Tabs from '../../components/Tabs';
import UserConfig from './UserConfig';
import RoleConfig from './RoleConfig';
import {  SwitchColors } from '@smf/ui-util-app';


export const StyledButtonContainer = styled(Grid)(({ theme }) => {
  return {
    flexDirection: 'row-reverse',
    padding: '1em',
    display: 'flex',
    textAlign: 'end',
    '& .MuiButton-outlined.Mui-disabled': {
      border: `1px solid ${theme.palette.border.lightRaven}`,
    },
    '& .MuiButton-root.Mui-disabled': {
      color: theme.customColors.white,
      backgroundColor: theme.customColors.darkGrey,
      opacity: 0.5,
    },
  };
});

export const StyledTableRow = styled(TableRow)(({ theme }) => {
  return {
    backgroundColor: theme.customColors.headerBlack,
    borderBottom: `0.75em solid ${theme.palette.background.configLighterDark}`,
    display: 'flex',
    justifyContent: 'space-between',
    '& th': {
      verticalAlign: 'top',
    },
  };
});

export const StyledIconButton = styled(IconButton)(({ theme }) => {
  return {
    height: '1.5em',
    borderRadius: 'unset',
    borderRight: `1px solid ${theme.customColors.greyishBlue}`,
    borderLeft: `1px solid ${theme.customColors.greyishBlue}`,
    paddingLeft: '1em',
    paddingRight: '1em',
    "&:disabled": {
      opacity: 0.5
    }
  };
});

export const SectionGrid = styled(Grid)(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.configLighterDark,
    width:'100%',
    height: 'calc(100% - 6.375em)',
    maxHeight: 'calc(100% - 6.375em)',
    overflowY: 'auto',
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
      color: theme.customColors.greyishWhite,
      '& .MuiInputBase-root.Mui-disabled': {
        borderColor: theme.customColors.darkGrey,
      },
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset',
    },
    '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-icon': {
      color: theme.customColors.white,
    },
    '& .MuiFormHelperText-root.MuiFormHelperText-filled': {
      display: 'none',
    },
  };
});

export const SearchBoxGrid = styled(Grid)(({ _theme }) => {
  return {
    textAlign: 'end',
    position: 'relative',
    '& input': {
      padding: '7px 10px 7px 40px',
      fontWeight: '300',
      fontSize: '16px',
      background: '#363636',
      color: '#fff',
      border: 'none',
      focus: 'none',
      outline: 'none',
      width: '260px',
      borderRadius: '5px',
    },
  };
});

export const ModalPaper = styled(Grid)(({ theme }) => {
  return {
    width: 'max-content',
    backgroundColor: SwitchColors.blackGrey,
    border: `1px solid ${theme.customColors.black}`,
    borderRadius: '.5em',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 0),
    zIndex:2
  };
});

export const SearchIconSpan = styled(Box)(({ _theme }) => {
  return {
    position: 'absolute',
    right: '0',
    zIndex: '1',
    marginRight: '14.4em',
    top: '0.5em',
  };
});


const AccessConfigGrid = styled(Grid)(({ theme }) => {
  return {
      backgroundColor: theme.customColors.dullDark,
      padding: '1em 1.5em 1.25em 2em',
      maxHeight: '100%',
  };
});


const AccessConfigContainer = () => {
  const [selectedTab, setSelectedTab] = useState(
    ACCESS_CONFIGURATION_CONSTANTS.TAB_ITEMS[0].value
  );

  const handleTabChange = (_e, value) => {
    setSelectedTab(value);
  };

  const [genciRoles, setGenciRoles] = useState([]);


  return (
    <AccessConfigGrid container direction="column" >
      <Grid item container>
        <Grid item container sm={11}>
          <Grid item>
            <Box marginBottom={3.625}>
              <Typography variant="h4" sx={{fontWeight: 700}}>
                {ACCESS_CONFIGURATION_CONSTANTS.MAIN_HEADING}
              </Typography>
            </Box>
          </Grid>
          <Grid item container>
            <Tabs
            id="styledTab"
              activeValue={selectedTab}
              textList={ACCESS_CONFIGURATION_CONSTANTS.TAB_ITEMS}
              onChange={(e, value) => handleTabChange(e, value)}
            />
          </Grid>
        </Grid>
      </Grid>
      {selectedTab === 1 && <UserConfig genciRoles={genciRoles}/>}
      {selectedTab === 0 && <RoleConfig setGenciRoles={setGenciRoles}/>}
    </AccessConfigGrid>
  );
};

export default AccessConfigContainer;
