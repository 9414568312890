import React from "react";
import { DatePicker } from '@mui/x-date-pickers';
import { watchListColors } from '@smf/ui-util-app';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';

const mui5Theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: watchListColors.markerGreen,
      },
      secondary: {
        main: watchListColors.markerGreen,
      },
    },
    primary: watchListColors.markerGreen,
    secondary: watchListColors.markerGreen,
  });

function GeneralDatepicker({
  disablePast,
  label,
  value,
  inputFormat,
  onChange,
  renderInput,
  disabled = false,
  ...other
}) {
  return (
    <ThemeProvider theme={mui5Theme}>
      <DatePicker
        disablePast={disablePast}
        id={label}
        value={value}
        inputFormat={inputFormat || 'MM/DD/YYYY'}
        onChange={onChange}
        renderInput={renderInput}
        disabled={disabled}
        data-testid={`smf-datepicker`}
        {...other}
      />
    </ThemeProvider>
  );
}

export default GeneralDatepicker;
