import React from "react";
import { Button, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { BUTTON_SIZE, BUTTON_TYPE } from "../../constants";

const CustomButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== "type" && prop !== "buttonSize",
})(({ theme, type, buttonSize }) => {
	const isPrimary = type === BUTTON_TYPE.PRIMARY;
	const isStandard = buttonSize === BUTTON_SIZE.STANDARD;
	return {
		height: isStandard ? "2.25rem" : "1.5rem", // As per figma
		margin: theme.spacing(0.5),
		color: isPrimary
			? theme.palette.text.secondary
			: theme.palette.text.primary,
		border: isPrimary
			? `0.0625rem solid ${theme.palette.background.saveGreen}`
			: `0.0625rem solid ${theme.palette.text.primary}`,
		boxSizing: "border-box",
		borderRadius: "1.25rem",
		minWidth: isStandard ? "6.5rem" : "3rem",
		fontSize: isStandard ? ".875rem" : ".75rem", // As per figma
		background: isPrimary ? theme.palette.background.saveGreen : "inherit",
		"&:hover": {
			color: isPrimary
				? theme.palette.text.primary
				: theme.palette.text.secondary,
			background: isPrimary ? "inherit" : theme.palette.text.primary,
		},
		"&:disabled": {
			color: isPrimary
				? theme.palette.text.lightYellowishGray
				: theme.palette.background.darkGrey,
			background: isPrimary ? theme.palette.background.darkGrey : "transparent",
			border: `0.0625rem solid ${
				isPrimary
					? theme.palette.border.lightRaven
					: theme.palette.background.darkGrey
			}`,
		},
		textTransform: "none",
	};
});

function GeneralButton({
	type = BUTTON_TYPE.PRIMARY,
	variant,
	size,
	onClick,
	color,
	disabled,
	text = BUTTON_TYPE.PRIMARY,
	children,
	buttonSize = BUTTON_SIZE.STANDARD,
	...other
}) {
	return (
		<CustomButton
			type={type}
			variant={variant || "contained"}
			size={size || "medium"}
			color={color || "primary"}
			onClick={onClick}
			role="button"
			disabled={disabled || false}
			buttonSize={buttonSize}
			data-testid={`smf-general-button`}
			{...other}
		>
			{children || <Typography variant="subtitle1">{text}</Typography>}
		</CustomButton>
	);
}

GeneralButton.propTypes = {
	type: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	size: PropTypes.string,
	color: PropTypes.string,
	variant: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	buttonSize: PropTypes.string,
};

export default GeneralButton;
